@import 'styles/config';

.footerBottomContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  
  row-gap: 16px;

  margin-top: scale-to-em(64px);
  padding-top: scale-to-em(24px);
    
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.footerBottom {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;

  font-size: $font-size-button;

  &__item {
    display: flex;
    align-items: center;
    @media (max-width: 600px) {
      margin-left: 8px;
    }

  }
  @media (min-width: 600px) {
    &__item:not(:last-child)::after {
      content: '';

      display: block;

      margin-right: scale-to-em(16px);
      margin-left: scale-to-em(16px);

      width: 4px;
      height: 4px;

      border-radius: 4px;

      background-color: white;
 
    }
  }

  // reset RichText
  & &__content {
    p,
    ul {
      margin: 0;
      padding: 0;
    }

    ul li:not(:last-child) {
      margin-bottom: 0;
    }

    a {
      @include footer-link;
    }
  }
}

.footerBottomCertifications {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  

}
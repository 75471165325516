.mobileNavbar {
  display: block;

  position: absolute;
  inset: 64px 0 0 0;

  padding: 16px 24px;

  height: calc(100dvh - 64px);

  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.92);

  .mobileNavList {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .item {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.1;

    color: var(--os-palette-primary);

    button {
      padding: 16px;

      border-radius: 8px;

      &:hover,
      &:focus,
      &:active {
        background: var(--os-color-bkg-button-clear-focus);
      }
    }
  }
}

.mobileSublist {
  display: flex;
  flex-direction: column;

  position: absolute;
  inset: 0;

  margin: 8px 24px 24px;

  overflow-y: auto;

  border-radius: 16px;

  opacity: 0;

  background: var(--color-card-background, #ffffff);
  box-shadow: 0px 0px 56px 0px #0000000f;

  transform: translateX(110%);

  .sectionTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    position: sticky;
    top: 0;

    padding: 24px;

    width: 100%;

    font-size: 18px;
    font-weight: 800;

    .icon {
      width: 16px;
    }

    color: #6b4b8f;
  }

  .groups {
    display: flex;
    flex-direction: column;
    gap: 32px;

    padding: 0 36px 40px;
  }

  .group {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .groupTitle {
      font-size: 24px;
      font-weight: 800;

      color: var(--text-text-purple, #320564);
    }

    .groupItems {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .groupItem {
      font-size: 20px;
      font-weight: 400;

      display: flex;
      align-items: center;

      color: var(--text-text-purple, #320564);
      position: relative;

      &.isActive {
        padding-left: 16px;
      }

      a {
        text-decoration: none;
      }

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-top: 2px;
        margin-right: 8px;
        background-color: var(--os-palette-secondary);
        position: absolute;
        top: 10px;
        left: 0px;
      }
    }
  }

  .featuredWrapper {
    padding: 0 16px 16px;
  }
}

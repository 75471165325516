@import '~styles/config';

.liveChatButton {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  position: fixed;
  z-index: 9999;
  right: 8px;
  bottom: 16px;

  width: 60px;
  height: 60px;

  border-radius: 30px;

  background-color: #fff;
  color: #fff;

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__modal > div {
    max-width: 600px;
  }

  &__modalWrap {
    padding: scale-to-em(20px) var(--gutter) var(--gutter);

    p {
      margin-bottom: var(--gutter);
    }
  }

  &__cookiehubError {
    color: $color-error;
  }
}

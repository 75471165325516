@import 'styles/config';

.layout {
  min-height: 100vh;
  padding: 0 8px;

  main {
    padding-top: 64px;
  }

  @media (min-width: $min-desktop) {
    padding: 0 32px;

    main {
      padding-top: 108px;
    }
  }
}

@import 'styles/config';

.footer {
  @include section(80, 32, 40, 32);

  position: sticky;
  top: 100vh;

  border-radius: 8px;

  background: var(--color-secondary, var(--color-background));

  color: $color-font-contrast;

  &__grid {
    row-gap: var(--gutter);

    align-items: space-between;
  }
}

// example of static font file css declarations
// for google fonts / typekit, use this: https://nextjs.org/docs/basic-features/font-optimization
@import url('//hello.myfonts.net/count/3848bd');

@font-face {
  font-family: FFMark;
  font-weight: 350;
  font-display: swap;
  src: url('/fonts/37A9BF_7_0.woff2') format('woff2');
}
@font-face {
  font-family: FFMark;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/3848BD_0_0.woff2') format('woff2');
}

@font-face {
  font-family: FFMark;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/3848BD_1_0.woff2') format('woff2');
}

@font-face {
  font-family: FFMark;
  font-weight: 700;
  font-display: swap;

  src: url('/fonts/3848BD_2_0.woff2') format('woff2');
}

@font-face {
  font-family: FFMark;
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('/fonts/3848BD_3_0.woff2') format('woff2');
}

@font-face {
  font-family: FFMark;
  font-weight: 900;
  font-display: swap;
  src: url('/fonts/3848BD_4_0.woff2') format('woff2');
}

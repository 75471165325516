@import 'styles/config';

.header {
  padding: 16px;

  border-radius: var(--os-radius-medium, 8px);

  backdrop-filter: blur(6px);
  background: rgba(255, 255, 255, 0.92);

  transition: 'background 0.3s';
  
  >div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 8px;
  }
  
  @media (min-width: $min-desktop) {
    padding: 7px 16px;
  }
  
  @media (max-width: 1079px) {
    .nav-open & {
      background: rgba(255, 255, 255, 1);
    }
  }

  &__logoContainer {
    flex-grow: 1;
    flex-basis: 0;

    height: 32px;
  
    svg {
      height: 100%;

      cursor: pointer;
    }
  
    @media (max-width: 1078px) {
      svg #logo-text {
        transition: opacity 0.3s;
      }
  
      .nav-open & {
        svg #logo-text {
          opacity: 0;
        }
      }
    }
  }

  &__actionsContainer {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: flex-end;
      
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      
      svg {
        width: 18px;
      }
      
      @media (max-width: 1078px) {
        padding: 12px;
      
        &:hover {
          padding: 12px;
        }
      
        svg {
          width: 24px;
        }
      }
    }
      
    a {
      text-decoration: none;
    }
    
  }

}

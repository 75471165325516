@import 'styles/config';

.modal {
  @include fit(fixed);

  contain: strict;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: $zindex-modal;

  padding-right: 0;

  padding-left: 0;

  width: 100vw;
  height: 100%;

  overflow: hidden;

  background-color: var(--color-backdrop, rgba(0, 0, 0, 0.3));

  &.enter {
    opacity: 0;
  }

  &.enterActive {
    opacity: 1;

    transition: opacity var(--transition-duration) $default-ease;
  }

  &.exit {
    opacity: 1;
  }

  &.exitActive {
    opacity: 0;

    transition: opacity var(--transition-duration) $default-ease;

    @media (prefers-reduced-motion: reduce) {
      transition-duration: 1ms;
    }
  }

  &__backdrop {
    @include fit(fixed);

    width: 100%;
    min-height: 100%;

    &:focus {
      outline: none;
    }
  }

  &__inner {
    position: relative;

    width: 80vw;
    max-width: 100%;
    height: 80vh;
    max-height: 100%;

    overflow: auto;

    border-radius: 8px;

    background-color: var(--color-background);    
    
    &:focus {
      outline: none;
    }

  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > button {
      @media (min-width: $min-desktop) {
        padding: 32px;      
      }
      padding: 16px;
      margin-right: -16px;

      font-size: 32px;
      > svg {
        width: 28px;
        
        
      }
    }

 
  }

}

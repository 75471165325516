/* global generic form styles */
@mixin input-defaults {
  display: inline-block;

  padding-left: 1.5ch;
  padding-right: 1.5ch;
  padding-top: 0;
  padding-bottom: 0;

  border: 1px solid transparent;
  border-radius: 16px;
  //border-bottom: 1px solid var(--color-border);

  background-color: var(--color-input-background);
  color: var(--color-font);

  @if variable-exists(input-height) {
    display: inline-flex;
    align-items: center;

    height: $input-height;
  }

  &:disabled {
    color: var(--color-disabled, rgba($color-font, 0.6));
  }

  &:focus,
  &:focus-visible,
  &:active {
    border-color: #59bcf4;
    outline-color: #59bcf4;
  }
}

@mixin input-disabled {
  border-color: var(--color-background-disabled);

  opacity: 0.9;

  background-color: var(--color-background-disabled);
}

@mixin form-label {
  padding-right: 10px;

  color: var(--color-form-label, $color-font);
}

@mixin ui-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: $ui-button-size;
  height: $ui-button-size;
}

@mixin ui-open {
  &::before,
  &::after {
    @include fit;

    content: '';

    margin: auto;

    width: 20px;
    height: 1px;

    background-color: currentColor;
  }

  &::before {
    transform: translateY(-4px);
  }

  &::after {
    transform: translateY(4px);
  }
}

@mixin ui-close {
  &::before,
  &::after {
    @include fit;

    content: '';

    margin: auto;

    width: 20px;
    height: 1px;

    background-color: currentColor;

    transform: rotate(-45deg);

    transition: transform $transition-duration $default-ease;
  }

  &::after {
    transform: rotate(45deg);
  }
}

@mixin ui-card-background {
  position: relative;

  border-radius: 8px;

  background-color: var(--color-card-background, #fff);

  &::after {
    @include fit;

    content: '';

    z-index: -1;

    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);

    transition: opacity $transition-duration $default-ease;
  }
}

// put form items in a grid
@mixin ui-form-layout {
  display: grid;
  gap: var(--form-gutter, var(--gutter));

  max-width: 450px;

  @media (min-width: $min-tablet) {
    &.columns {
      grid-template: auto / repeat(2, minmax(0, 1fr));

      max-width: none;
    }
  }

  @media (min-width: $min-desktop) {
    max-width: none;
  }
}

@mixin ui-form-layout-item {
  width: 100%;

  // textarea or fieldset will span all (both) columns
  &.span {
    grid-column: 1 / -1;
  }
}

@import '~styles/config';

.banner {
  display: flex;

  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;

  margin: 0 auto;

  width: 100%;

  max-width: 1728px; 
}

.dot {
  display: none;
      
  @media (min-width: $max-tablet) {
    display: block; 
  }
}

.info {
  gap: 8px;
  flex-grow: 1;

  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  
  @media (min-width: $max-tablet) {
    display: grid;
    grid-template-columns:  auto auto 1fr;
    align-items: flex-start;
  }

}

.title {
  font-size: $h4-size-min;
  font-weight: 700;
  line-height: 1.5;
}

.description {
  font-size: $h4-size-min;
  font-weight: 400;
  line-height: 1.5;
}

.button {
  > div {
    width: 16px;
  }
  padding: 24px;
}

.heading {
  display: flex;
  gap: 8px;
  align-items: center;
}

.icon {
  width: 16px;
}
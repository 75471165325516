.menuButton {
  padding: var(--os-padding-button-clear);

  border-radius: var(--os-radius-medium, 8px);

  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;

  background-color: var(--os-color-bkg-button-clear);
  color: var(--os-color-text-button-clear);
  // transition quick spring for 600ms with stiffness of 300, damping of 20 and mass of 1
  transition: background-color 300ms,
    padding 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;

  &.isCurrent {
    background-color: var(--os-color-bkg-button-clear-focus);
  }

  &:hover,
  &:focus,
  &.isOpen {
    padding: var(--os-padding-button-clear-focus);

    background-color: var(--os-color-bkg-button-clear-hover);
  }

  &:active {
    padding: var(--os-padding-button-clear-active);

    background-color: var(--os-color-bkg-button-clear-active);
  }

  &:focus-visible {
    outline: 2px solid var(--os-color-outline);
  }
}

.os-button {
  padding: var(--os-padding-button);

  border-radius: var(--os-radius-medium, 8px);

  font-weight: 700;

  background-color: var(--os-color-bkg-button);
  color: var(--os-color-text-button);
  // transition quick spring for 600ms with stiffness of 300, damping of 20 and mass of 1
  transition: background-color 300ms,
    padding 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;

  &:hover {
    padding: var(--os-padding-button-hover);

    background-color: var(--os-color-bkg-button-hover);
  }

  &:focus,
  &.selected {
    padding: var(--os-padding-button-focus);

    background-color: var(--os-color-bkg-button-focus);
  }

  &:active {
    padding: var(--os-padding-button-active);

    background-color: var(--os-color-bkg-button-active);
  }

  &:focus-visible {
    outline: 2px solid var(--os-color-outline);
  }
}

$buttonVariants: ('clear', 'primary');

@each $variant in $buttonVariants {
  .baseButton-#{$variant} {
    font-weight: 500;
    border-radius: var(--os-radius-medium, 8px);
    padding: var(--os-padding-button-#{$variant});
    background: var(--os-color-bkg-button-#{$variant});
    color: var(--os-color-text-button-#{$variant});
    // transition quick spring for 600ms with stiffness of 300, damping of 20 and mass of 1
    transition: background 300ms,
      padding 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275) 0ms;

    &:hover {
      background: var(--os-color-bkg-button-#{$variant}-hover);
      padding: var(--os-padding-button-#{$variant}-hover);
    }

    &:focus,
    &.selected {
      background: var(--os-color-bkg-button-#{$variant}-focus);
      padding: var(--os-padding-button-#{$variant}-focus);
    }

    &:active {
      background: var(--os-color-bkg-button-#{$variant}-active);
      padding: var(--os-padding-button-#{$variant}-active);
    }

    &:focus-visible {
      outline: 2px solid var(--os-color-outline);
    }
  }
}

@import '~styles/config';

.button {
  appearance: none;
  display: flex;
  align-items: center;
  // dont stretch buttons by default
  align-self: flex-start;
  justify-content: center;
  justify-self: flex-start;
  gap: 8px;

  position: relative;

  padding: 15px 39px;

  width: fit-content;

  min-width: 298px;

  border-radius: 8px;

  font-weight: 700;

  text-decoration: none;

  background-color: var(--color-secondary);
  color: var(--color-font-contrast);

  &.iconRight {
    flex-direction: row-reverse;
  }

  @if variable-exists(button-width) {
    min-width: min(100%, #{$button-width});

    @if variable-exists(button-max-width) {
      max-width: $button-max-width;
    }
  }

  @if variable-exists(button-height) {
    min-height: $button-height;
  }

  @if variable-exists(font-size-button-min) and
    variable-exists(font-size-button)
  {
    @include responsive-font($font-size-button-min, $font-size-button);
  }

  &.disabled,
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;

    opacity: 0.6;
  }

  &__iconWrap {
    margin-top: 2px;
  }

}

@tailwind base;
@tailwind components;
@tailwind utilities;
// PROJECT-SPECIFIC global css should go here
@import 'reset';
@import 'fonts';
@import 'config';

// global form styles imported separately
@import 'forms';

:root {
  // LAYOUT
  --container-gutter: #{$container-gutter-mobile};
  --gutter: #{$gutter};

  --z-index-header: 10;
  --z-index-above-header: 11;

  // ANIMATION / TRANSITION
  --default-ease: #{$default-ease};
  --transition-duration: #{$transition-duration};

  // COLORS
  @if variable-exists(color-backdrop) {
    --color-backdrop: #{$color-backdrop};
  }

  --color-background: #{$color-background};
  --color-input-background: #{$color-input-background};
  --color-border: #{$color-border};
  --color-font: #{$color-font};
  --color-link: #{$color-link};
  --color-primary: #{$color-primary};
  --color-font-contrast: #fff;
  --color-hero-font: var(--color-font-contrast);
  --color-form-label: #{$color-form-label};
  --color-blue: #{$color-blue};
  --color-error: #{$color-error};

  --color-dark-grey: #{$color-dark-grey};

  @if variable-exists(color-secondary) {
    --color-secondary: #{$color-secondary};
  }

  @if variable-exists(color-font-contrast) {
    --color-font-contrast: #{$color-font-contrast};
  }

  @if variable-exists(color-placeholder) {
    --color-placeholder: #{$color-placeholder};
  }

  @if variable-exists(header-height-mobile) {
    --header-height: #{$header-height-mobile};
  }

  @media (min-width: $min-tablet) {
    --container-gutter: #{$container-gutter-tablet};
  }

  @media (min-width: $min-desktop) {
    --container-gutter: #{$container-gutter-desktop};

    @if variable-exists(header-height) {
      --header-height: #{$header-height};
    }
  }

  // DARK MODE
  @media (prefers-color-scheme: dark) {
    @if variable-exists(color-background-dark-mode) {
      --color-background: #{$color-background-dark-mode};
    }

    @if variable-exists(color-font-dark-mode) {
      --color-font: #{$color-font-dark-mode};
    }

    @if variable-exists(color-link-dark-mode) {
      --color-link: #{$color-link-dark-mode};
    }
  }

  @media (prefers-reduced-motion: reduce) {
    --transition-duration: 1ms;
  }
}

html {
  @include responsive-font($font-size-min, $font-size);

  @mixin html-overflow-hidden {
    overflow-y: hidden;

    body {
      padding-right: var(--scrollbar-width);

      touch-action: none;
    }
  }

  font-family: $font-family;
  line-height: $line-height;

  color: var(--color-font, $color-font);
  background: var(--color-background, $color-background);

  &.scroll-disabled {
    // if nav is open
    @include html-overflow-hidden;
  }

  &.nav-open {
    @media (max-width: $max-desktop) {
      @include html-overflow-hidden;
    }
  }
}

body {
  color: var(--color-font);
}

hr {
  border-color: var(--color-border, $color-border);
}

a {
  text-decoration: underline;

  @media (pointer: fine) {
    &:hover {
      text-decoration: none;
    }
  }
}

.powerStationImage .richTextImage {
  padding-right: scale-to-em(30px, $font-size);
  margin-bottom: scale-to-em(90px, $font-size);
}

.powerStationImage picture {
  position: relative;
}

.powerStationImage img {
  width: 100%;

  border-radius: 8px;
}

.powerStationImage picture::after {
  @include fit;
  content: '';

  z-index: -1;

  border-radius: 8px;

  background-color: $color-green;

  transform: translate3d(20px, 20px, 0);
}

// Cookiehub overrides
[class].ch2-btn {
  text-align: center;
}

[class][class][class].ch2-deny-all-btn {
  text-decoration: underline;
  text-transform: none;

  background: none;
  box-shadow: none;
  color: #333;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
*:focus {
  outline-color:#59bcf4;
}

// Token POC
:root {
  // Core tokens
  // Core Colors
  --os-palette-neutral-000: #ffffff;

  --os-palette-primary: #320564;
  --os-palette-secondary: #46c28c;

  // Core Radii
  --os-radius-none: 0;
  --os-radius-small: 4px;
  --os-radius-medium: 8px;
  --os-radius--rounded: 50%;

  // Semantic tokens
  --os-color-action-primary: var(--os-palette-primary);
  --os-color-outline: var(--os-palette-primary);

  // Component tokens
  // button / base
  --os-color-bkg-button: var(--os-color-action-primary);
  --os-color-bkg-button-hover: var(--os-color-action-primary);
  --os-color-bkg-button-focus: var(--os-color-action-primary);
  --os-color-bkg-button-active: var(--os-color-action-primary);
  --os-color-bkg-button-disabled: var(--os-color-action-primary);

  --os-color-text-button: var(--os-palette-neutral-000);
  --os-color-text-button-hover: var(--os-palette-neutral-000);
  --os-color-text-button-focus: var(--os-palette-neutral-000);
  --os-color-text-button-active: var(--os-palette-neutral-000);
  --os-color-text-button-disabled: var(--os-palette-neutral-000);

  --os-padding-button: 16px;
  --os-padding-button-hover: 14px 16px 18px;
  --os-padding-button-focus: 14px 16px 18px;
  --os-padding-button-active: 16px;

  --os-font-weight-button: 700;

  // button / primary
  --os-color-bkg-button-primary: linear-gradient(289.56deg,
  rgba(0, 0, 0, 0) 0%,
  rgba(0, 0, 0, 0) 100%),
    radial-gradient(141.6% 175.82% at 47.54% -9.9%,
  #7838ca 0%,
  rgba(161, 89, 254, 0) 0.01%,
  rgba(43, 8, 87, 0) 80.53%),
    linear-gradient(262.95deg, #320564 1.69%, #5fffc2 102.18%),
    var(--os-color-action-primary);
  --os-color-bkg-button-primary-hover: linear-gradient(289.56deg,
  rgba(0, 0, 0, 0.2) 0%,
  rgba(0, 0, 0, 0) 100%),
    radial-gradient(141.6% 175.82% at 47.54% -9.9%,
  #7838ca 0%,
  rgba(161, 89, 254, 0.62) 0.01%,
  rgba(43, 8, 87, 0) 80.53%),
    linear-gradient(262.95deg, #320564 1.69%, #5fffc2 102.18%),
    var(--os-color-action-primary);
  --os-color-bkg-button-primary-focus: linear-gradient(289.56deg,
  rgba(0, 0, 0, 0.2) 0%,
  rgba(0, 0, 0, 0) 100%),
    radial-gradient(141.6% 175.82% at 47.54% -9.9%,
  #7838ca 0%,
  rgba(161, 89, 254, 0.62) 0.01%,
  rgba(43, 8, 87, 0) 80.53%),
    linear-gradient(262.95deg, #320564 1.69%, #5fffc2 102.18%);
  --os-color-bkg-button-primary-active: linear-gradient(0deg,
  rgba(50, 5, 100, 0.4),
  rgba(50, 5, 100, 0.4)),
    linear-gradient(289.56deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%),
    radial-gradient(141.6% 175.82% at 47.54% -9.9%,
  #7838ca 0%,
  rgba(161, 89, 254, 0.62) 0.01%,
  rgba(43, 8, 87, 0) 80.53%),
    linear-gradient(262.95deg, #320564 1.69%, #5fffc2 102.18%);
  --os-color-bkg-button-primary-disabled: var(--os-color-action-primary,
  var(--os-color-bkg-button-disabled));

  --os-color-text-button-primary: var(--os-palette-neutral-000,
  var(--os-color-text-button));
  --os-color-text-button-primary-hover: var(--os-palette-neutral-000,
  var(--os-color-text-button-hover));
  --os-color-text-button-primary-focus: var(--os-palette-neutral-000,
  var(--os-color-text-button-focus));
  --os-color-text-button-primary-active: var(--os-palette-neutral-000,
  var(--os-color-text-button-active));
  --os-color-text-button-primary-disabled: var(--os-palette-neutral-000,
  var(--os-color-text-button-disabled));

  --fill-gradient: linear-gradient(290deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), radial-gradient(214.74% 189.7% at 47.54% -9.9%, #7838CA 0%, rgba(161, 89, 254, 0.62) 0.01%, rgba(43, 8, 87, 0.00) 80.53%), linear-gradient(263deg, #320564 1.69%, #5FFFC2 102.18%);
  --ui-button-button-fill: #320564;

  --os-padding-button-primary: var(--os-padding-button);
  --os-padding-button-primary-hover: var(--os-padding-button-hover);
  --os-padding-button-primary-focus: var(--os-padding-button-focus);
  --os-padding-button-primary-active: var(--os-padding-button-active);

  // button / clear
  --os-color-bkg-button-clear: transparent;
  --os-color-bkg-button-clear-hover: #ebe8ed;
  --os-color-bkg-button-clear-focus: #e5e1ea;
  --os-color-bkg-button-clear-active: #cdc4d7;
  --os-color-bkg-button-clear-disabled: var(--os-color-action-primary);

  --os-color-text-button-clear: var(--os-palette-primary);
  --os-color-text-button-clear-hover: var(--os-palette-primary);
  --os-color-text-button-clear-focus: var(--os-palette-primary);
  --os-color-text-button-clear-active: var(--os-palette-primary);
  --os-color-text-button-clear-disabled: var(--os-palette-primary);

  --os-padding-button-clear: 16px;
  --os-padding-button-clear-hover: 14px 16px 18px;
  --os-padding-button-clear-focus: 14px 16px 18px;
  --os-padding-button-clear-active: var(--os-padding-button-active);
}
@import '../../styles/config';

$transitionTiming: 200ms ease-out;
$dotSize: 8px;

.megamenu {
  margin: 8px 8px 0;

  height: auto;
  overflow: auto;

  border-radius: 8px;

  backdrop-filter: blur(12px);
  background: rgba(255, 255, 255, 0.92);

  @media (min-width: $min-desktop) {
    margin: 0 auto 0;
    width: 100%;
    max-width: 1400px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08),
      0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  }
}

.megamenu__container {
  display: flex;
  column-gap: 32px;

  color: #320564;

  margin: 16px;
  @media (min-width: $min-desktop) {
    margin: 32px;
  }
}

.column {
  flex: 1;
}

.groups {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  column-gap: 32px;
}

.featured {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  padding: 24px;

  color: #fff;
  text-decoration: none;
  border-radius: 16px;

  /* Fill/Gradient */
  background: linear-gradient(
      289.56deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      141.6% 175.82% at 47.54% -9.9%,
      #7838ca 0%,
      rgba(161, 89, 254, 0.62) 0.01%,
      rgba(43, 8, 87, 0) 80.53%
    ),
    linear-gradient(262.95deg, #320564 1.69%, #5fffc2 102.18%);

  transition: background-image 0.3s ease-in-out;

  @media (min-width: $min-desktop) {
    width: 380px;
    height: 306px;
    border-radius: 8px;
  }
}

.featured__title {
  @include h3;
  color: #fff;
}

.group {
  &__title {
    @include responsive-font(24px, $font-size);
    margin-bottom: 32px;

    font-size: 24px;
    font-weight: 800;
    line-height: 110%;
    letter-spacing: -0.02em;
  }
}

.linkItem {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    font-size: 20px;
    line-height: 150%;
  }

  .dot {
    width: 0;
    height: 0;
    border-radius: 50%;
    margin-top: 2px;
    margin-right: 0;
    transition: width $transitionTiming, height $transitionTiming, margin-right $transitionTiming;
    transition-delay: 70ms;
  }

  &.isActive {
    .dot {
      width: $dotSize;
      height: $dotSize;
      margin-right: $dotSize;
      background-color: var(--os-palette-secondary);
      transition: background-color $transitionTiming,
    }
  }

  &:hover {
    .dot {
    width: $dotSize;
    height: $dotSize;
    margin-right: $dotSize;
    background-color: var(--os-palette-primary);
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
@import 'styles/config';

.footerItem {
  width: 100%;

  font-size: $font-size-button;

  &:last-child:not(:only-child) {
    margin-top: auto;
  }

  &__title {
    padding-bottom: calc(var(--gutter));
    margin-bottom: calc(var(--gutter));

    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    font-size: scale-to-em(20px);
    font-weight: 800;
    line-height: 1.5;
  }

  &__list a {
    @include footer-link;
    display: block;
    
    padding-bottom: scale-to-em(16px);
  }

  img {
    max-width: 170px;
  }

  // reset RichText
  & &__content {
    p,
    ul {
      margin: 0;
      padding: 0;
    }

    ul li:not(:last-child) {
      margin-bottom: 0;
    }

    a {
      @include footer-link;
    }
  }
}

.footerContanct {
  display: flex;
  flex-wrap: wrap;

  &__textfield {
    flex: 1 1 50%;

    padding-bottom: scale-to-em(16px);
    a {
      @include footer-link;
    }
  
  }

  &__rightTextfield{
    padding-bottom: scale-to-em(16px);
  }

}
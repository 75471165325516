@import 'styles/config';

.social {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--gutter) * 0.5);

  position: relative;
  z-index: 0;

  &__item {
    flex: none;

    position: relative;

    svg {
      height: 24px;
      width: auto;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      z-index: 0;

      width: 100%;
      height: 100%;

      color: $color-background;

      transition: color 250ms ease-in-out;
    }
  }

  &__item:hover >a {
    color: $color-primary;;
  }
}

@import 'styles/config';

.grid {
  $color-gap: rgba(#000, 0.025);
  $color-highlight: rgba(#fff, 0.15);

  position: relative;
  z-index: 1000;

  pointer-events: none;

  &__container {
    @include container;
    @include fit(fixed);

    height: 100%;
  }

  &__visual {
    position: relative;

    width: 100%;
    height: 100%;

    pointer-events: none;

    opacity: 0;

    animation: fade-in var(--transition-duration) forwards;

    @media (max-width: $max-mobile) {
      outline: 1px solid $color-gap;
    }

    @media (min-width: $min-mobile) {
      outline: none;

      background-image: repeating-linear-gradient(
        to right,
        $color-highlight,
        $color-highlight calc((100% / #{$grid-column-count}) - var(--gutter)),
        calc((100% / #{$grid-column-count}) - var(--gutter)),
        $color-gap calc(100% / #{$grid-column-count})
      );
      background-size: calc(100% + var(--gutter)) 100%;
      background-position: 0 0;
    }

    @keyframes fade-in {
      to {
        opacity: 1;
      }
    }

    // visualise container gutter
    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 0;
      left: 100%;

      width: 50vw;
      height: 100%;

      background: $color-gap;
    }

    &::after {
      left: auto;
      right: 100%;
    }
  }

  // toggle control button
  &__button {
    position: fixed;
    right: 75px;
    top: 0;
    z-index: 10000;

    padding: 0 15px;

    height: 24px;
    width: 40px;

    border-radius: 0 0 3px 3px;

    cursor: pointer;
    pointer-events: auto;

    color: #999;
    background-color: #fff;

    &:hover {
      color: #000;
    }

    &:focus {
      outline: none;
    }

    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 4px;

      width: 6px;
      height: 14px;

      border: solid currentColor;
      border-width: 0 2px;
    }

    &::before {
      left: 13px;
    }

    &::after {
      right: 13px;
    }
  }
}

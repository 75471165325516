@import 'styles/config';

.previewNotice {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $zindex-preview;

  padding: 1rem;

  width: 100%;

  border-top: 2px solid $color-background;

  text-align: center;

  background-color: $color-primary;

  & > a {
    font-weight: 700;
    font-variation-settings: 'wght' 700;

    color: $color-background;
  }
}

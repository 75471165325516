// an example page transition. transforms an element across the page
// css classes triggered by a <SwitchTransition />
// be sure to use timings based around the custom property var(--ptd) (Page Transition Duration);
// any transition duration + delay should not equal more than var(--ptd);
// var(--ptd) is defined in <PageTransition /> as PAGE_TRANSITION_DURATION

$loadingBarWidth: 50vw;
$loadingBarHiddenWidth: calc(100vw + $loadingBarWidth);

@import 'styles/config';

@keyframes loadingTransition {
  0% {
    transform: translateX(-($loadingBarWidth));
  }
  70% {
    transform: translateX($loadingBarHiddenWidth);
  }
  100% {
    transform: translateX($loadingBarHiddenWidth);
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-above-header);

  width: $loadingBarWidth;
  height: 4px;

  background: linear-gradient(
    90deg,
    transparent 0%,
    var(--os-palette-secondary) 25%,
    var(--os-palette-secondary) 75%,
    transparent 100%
  );

  transform: translateX(-($loadingBarWidth));
  animation: loadingTransition 1.2s infinite linear;

  @media (min-width: $min-tablet) {
    height: 6px;
  }
}

.pageTransition {
  // Blur background when nav-drawer on mobile is open
  &.blur {
    transition: filter 0.1s ease;
    transition-delay: 0.2s;
    filter: blur(12px);
  }
  &.enterActive,
  &.exitActive {
    pointer-events: none;
  }

  &.exit {
    visibility: visible;
  }

  &.exitActive {
    // need to visually hide exiting page immediately due to css unload
    // transition + duration always triggers transitionEnd callback
    // do not remove this behaviour unless nextjs changes css unload behaviour
    // https://github.com/vercel/next.js/issues/17464
    visibility: hidden;

    opacity: 0;

    transition: visibility 1ms;
  }

  // simple fade-in
  &:not(.animate) {
    &.enter {
      opacity: 0;
    }

    &.enterActive {
      opacity: 1;

      transition: opacity var(--ptd) $default-ease;

      @media (prefers-reduced-motion: reduce) {
        transition-duration: 1ms;
      }
    }
  }

  // only rendered when "animate" option explicitly set

  // content transition
  &__inner {
    .enter & {
      opacity: 0;
    }

    .enterActive & {
      opacity: 1;

      // ptd: page-transition-duration
      transition: opacity calc(var(--ptd) * 0.5) $default-ease;
      transition-delay: calc(var(--ptd) * 0.5);

      @media (prefers-reduced-motion: reduce) {
        transition-duration: 1ms;
        transition-delay: 0ms;
      }
    }
  }

  // animated element
  &__animationEl {
    @include fit(fixed);

    contain: strict;

    visibility: hidden;

    background-color: var(--color-primary);

    transform: translate3d(0, 0, 0);

    @media (prefers-reduced-motion: reduce) {
      display: none;
    }

    .enter & {
      visibility: visible;

      transform: translate3d(105%, 0, 0);
    }

    .enterActive & {
      transform: translate3d(-105%, 0, 0);

      transition: transform var(--ptd) $default-ease;
    }
  }
}
